import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {CubeTextureLoader, Vector3} from "three";
import {gsap} from 'gsap'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader} from "three/examples/jsm/loaders/FontLoader";
import CANNON from 'cannon'
import boxVertexShader from '../static/shaders/box/vertex.glsl'
import boxFragmentShader from '../static/shaders/box/fragment.glsl'
import bordersVertexShader from '../static/shaders/borders/vertex.glsl'
import bordersFragmentShader from '../static/shaders/borders/fragment.glsl'
import { VRButton } from 'three/addons/webxr/VRButton.js';

const WEIGHT = 10
const HEIGHT = 20
const BOUNDY = -21
const BOUNDSZ = [-5, 6]

const LOOSETEXT =  "You lost! Your scores: "
const MENUTEXT = "T E T R I S"
const RTEXT = "Press R to start!"
const ADTEXT = "Press A D to move left and right"
const STEXT = "Press S to move down"
const XTEXT = "Press X to play / stop music"
const FONT = '/fonts/helvetiker_regular.typeface.json'
const TEXTGEOMETRYPARAMETRES = {
    size: 1, height: 0.2, curveSegments: 2, bevelEnabled: true, bevelThickness: 0.03, bevelSize: 0.02, bevelOffset: 0, bevelSegments: 5}


let scene, world, camera
let sizes
let clock
let loadingBarElement
let fontLoader, textureLoader, cubeTextureLoader, audioLoader
let bordersMaterial, textMaterial
let loadingManager
let canvas
let environmentMap
let controls
let renderer
let listener, sound
let spaceTexture, spaceMaterial
// let overlayMaterial
// let overlayGeometry


function init(){

    /**
     * World + Scena
     */

    world = new CANNON.World()
    world.gravity.set(0, -10, 0)
    world.broadphase = new CANNON.NaiveBroadphase()
    world.solver.iteration = 40

    scene = new THREE.Scene();

    /**
     * Sizers
     */

    sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    /**
     * Camera
     */

    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)

    /**
     * Clock
     */

    clock = new THREE.Clock()

    /**
     * Loaders
     */

    loadingBarElement = document.querySelector('.loading-bar')
    loadingManager = new THREE.LoadingManager(
        // Loaded
        () => {
            window.setTimeout(()=> {
                // gsap.to(overlayMaterial.uniforms.uAlpha, {duration: 3, value: 0})
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
            },500)
        },
        // Progress
        (itemsUrl, itemsLoaded, itemsTotal) => {
            const progressRatio = itemsLoaded / itemsTotal
            loadingBarElement.style.transform = `scaleX(${progressRatio})`
        },
    )

    fontLoader = new FontLoader(loadingManager)
    textureLoader = new THREE.TextureLoader(loadingManager)
    cubeTextureLoader = new CubeTextureLoader(loadingManager)


    /**
     * Environment
     */

    environmentMap = cubeTextureLoader.load([
        '/textures/environmentMaps/cubemap/px.png',
        '/textures/environmentMaps/cubemap/nx.png',
        '/textures/environmentMaps/cubemap/py.png',
        '/textures/environmentMaps/cubemap/ny.png',
        '/textures/environmentMaps/cubemap/pz.png',
        '/textures/environmentMaps/cubemap/nz.png'
    ])

    environmentMap.encoding = THREE.sRGBEncoding

    scene.background = environmentMap
    scene.environment = environmentMap

    /**
     * Controls
     */

    canvas = document.querySelector('canvas.webgl')

    controls = new OrbitControls(camera, canvas)

    /**
     * Renderer
     */

    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    })

    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.toneMapping = THREE.ReinhardToneMapping
    renderer.toneMappingExposure = 3
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.xr.enabled = true;
    document.body.appendChild( VRButton.createButton( renderer ) );


    /**
     * Audio
     */

    listener = new THREE.AudioListener();

    scene.add( listener );
    sound = new THREE.Audio( listener );

    audioLoader = new THREE.AudioLoader();
    audioLoader.load( 'sounds/tetris.mp3', function( buffer ) {
        sound.setBuffer( buffer );
        sound.setLoop( true );
        sound.setVolume( 0.5 );
    });

    /**
     * Materials
     */

    bordersMaterial = new THREE.ShaderMaterial({
        uniforms: {
            alphaNoise: {
              value: 1
            },
            alphaScale: {
                value: 1
            },
            scale: {
                value: 3
            },
            frequency: {
                value: 9
            },
            noiseScale: {
                value: 6.4
            },
            ringScale: {
                value: 0.6
            },
            color1: {
                value : new THREE.Color('#703600')//[0, 0, 13]
            },
            color2: {
                value: new THREE.Color('#ea8021')//[24, 30, 95]
            }
        },
        vertexShader: bordersVertexShader,
        fragmentShader: bordersFragmentShader
    })

    textMaterial = new THREE.MeshMatcapMaterial({matcap: textureLoader.load("textures/my_matcap.png")});

    /**
     * Grid
     */

    spaceTexture = textureLoader.load( "/textures/block.png" )
    spaceTexture.wrapS = THREE.RepeatWrapping;
    spaceTexture.wrapT = THREE.RepeatWrapping;
    spaceTexture.repeat.set( 10, 20 );

    spaceMaterial = new THREE.MeshBasicMaterial({ map : spaceTexture, transparent: true, opacity: 0.5 });


    /**
     * Overlay
     */

    // overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
    // overlayMaterial = new THREE.ShaderMaterial({
    //     transparent: true,
    //     uniforms:{
    //         uAlpha: {value: 1}
    //     },
    //     vertexShader: `
    //     void main()
    //     {
    //         gl_Position = vec4(position, 1.0);
    //     }
    // `,
    //     fragmentShader: `
    // uniform float uAlpha;
    //
    //  void main()
    //     {
    //         gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
    //     }
    // `
    // })

}


let scores, speedX, textGroup, textStartGroup, gameStarted, loose, bordersBoxGroup
let allSpace
let pause


/**
 * Setup Menu
 */

function restartAll() {

    scene.clear()

    scores = 0
    speedX = 1

    textGroup = new THREE.Object3D()
    textStartGroup = new THREE.Object3D()
    bordersBoxGroup = new THREE.Object3D()
    fillStartMenu()

    gameStarted = false
    loose = false

    scene.add(textStartGroup)

    camera.position.set(50, -7.55, 0.32)
    controls.target.set(100, -10,0 )
    // controls.target.set(0, -10,0 )
    controls.enableDamping = true
    scene.add(camera)
    pause = false;

    allSpace = [ [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]],
        [ [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null],[-100, null, null], [-100, null, null], [-100, null, null], [-100, null, null]]]

}


/**
 * Start Game
 */


let group, nextGroup, allGroups, pressS, plane
let looseEffect, cannonBlocks, difElapsedTime, firstTime

function gameStart() {



    gameStarted = true
    pressS = false
    cannonBlocks = []

    group = createGroup()
    nextGroup = createGroup()

    allGroups = []
    nextGroup.position.z = 10

    allGroups = []
    allGroups.push(group)

    scene.add(nextGroup)
    scene.add(group)

    changeScores()

    bordersBoxGroup = createBorders()
    scene.add(bordersBoxGroup)

    plane = new THREE.Mesh(new THREE.PlaneGeometry(10, 20), spaceMaterial);
    plane.material.side = THREE.DoubleSide;
    plane.position.y -= 10.5
    plane.position.z += 0.5
    plane.rotation.y = Math.PI / 2;
    scene.add(plane)


    looseEffect = 0
    difElapsedTime = 0
    firstTime = true
}

init()
restartAll()
// gameStart()


/**
 * Create TextMesh
 */

function crText(text, font, scale, x, y, z, rotation) {
    let textGeometry = new TextGeometry(text, Object.assign({}, {font: font}, TEXTGEOMETRYPARAMETRES))
    let textMesh = new THREE.Mesh(textGeometry, textMaterial)
    textMesh.scale.set(scale, scale, scale)
    textMesh.position.set(x, y, z)
    textMesh.rotateY(rotation)
    return textMesh
}

/**
 * Crqate Start Menu
 */

function fillStartMenu() {

    textStartGroup.clear()
    fontLoader.load( FONT, function ( font ) {

        textStartGroup.add(crText(MENUTEXT, font, 10, 100, 0, -30, -Math.PI /2))
        textStartGroup.add(crText(RTEXT, font, 3, 100, -10, -30, -Math.PI /2))
        textStartGroup.add(crText(ADTEXT, font, 3, 100, -20, -30, -Math.PI /2))
        textStartGroup.add(crText(STEXT, font, 3, 100, -30, -30, -Math.PI /2))
        textStartGroup.add(crText(XTEXT, font, 3, 100, -40, -30, -Math.PI /2))

    } )
}



/**
 * EventListeners
 */

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', ()=>{
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

    if (!fullscreenElement) {
        if (renderer.domElement.requestFullscreen) {
            renderer.domElement.requestFullscreen()
        } else if (renderer.domElement.webkitRequestFullscreen) {
            renderer.domElement.webkitRequestFullscreen()
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        }
    }
})

window.addEventListener('keydown', onDocumentKeyDown, false);


function onDocumentKeyDown(event) {

    var keyCode = event.which;

    if (keyCode === 69) {

        group.rotateX(-Math.PI/2)
        renderer.render(scene, camera)

        if (checkGroupTouch(group, allSpace, 0, 0)) {
            if (checkGroupTouch(group, allSpace, 0, 1)) {
                if (checkGroupTouch(group, allSpace, 0, -1)) {
                    group.rotateX(Math.PI/2)
                }
                else {
                    group.position.z += -1
                }
            }
            else {
                group.position.z += 1
            }
        }
        renderer.render(scene, camera)


    } else if (keyCode === 81) {

        group.rotateX(Math.PI/2)
        renderer.render(scene, camera)

        if (checkGroupTouch(group, allSpace, 0, 0)) {
            if (checkGroupTouch(group, allSpace, 0, 1)) {
                if (checkGroupTouch(group, allSpace, 0, -1)) {
                    group.rotateX(-Math.PI/2)
                }
                else {
                    group.position.z += -1
                }
            }
            else {
                group.position.z += 1
            }
        }
        renderer.render(scene, camera)

    } else if (keyCode === 83) {

        pressS = true

    } else if (keyCode === 65) {

        if (!checkGroupTouch(group, allSpace, 0, 1))
        {
            group.position.setZ(group.position.z + 1)
            renderer.render(scene, camera)
        }

    } else if (keyCode === 68) {
        if (!checkGroupTouch(group, allSpace, 0, -1))
        {
            group.position.setZ(group.position.z -1)
            renderer.render(scene, camera)
        }
    } else if (keyCode === 82) {
        restartAll()
        gsap.to(camera.position, {duration: 3, x: 19})
        controls.target.set(0, -10,0 )
        gameStart()

    }
    // else if (keyCode === 70) {
    //
    //     gsap.to(camera.position, {duration: 3, x: 50})
    //     controls.target.set(100, -10,0 )
    // }
    // else if (keyCode === 84) {
    //
    //     gsap.to(camera.position, {duration: 3, x: 19})
    //     controls.target.set(0, -10,0 )
    // }
    else if (keyCode === 88) {
        if (sound.isPlaying) {
            sound.stop()
        } else {
            sound.play()
        }
    }
}


/**
 * Create Pattern - Group of clocks
 */

function createGroup() {

    const group = new THREE.Object3D();

    var colorR = getRandom(5)

    let red = 0
    let green = 0
    let blue = 0

    if (colorR < 1) {
        red = 1
    } else if (colorR < 2) {
        green = 0.8
    } else if (colorR < 3) {
        blue = 1
    } else if (colorR < 4) {
        red = 1
        green = 0.9
    } else {
        red = 0.9
        blue = 1
    }

    var baseBoxMaterial = new THREE.ShaderMaterial({
        uniforms: {
            size: {
                value: new THREE.Vector3(1, 1, 1).multiplyScalar(0.5)
            },
            thickness: {
                value: 0.01
            },
            smoothness: {
                value: 0.1
            },
            redC: {
                value: red
            },
            greenC: {
                value: green
            },
            blueC: {
                value: blue
            }
        },
        vertexShader: boxVertexShader,
        fragmentShader: boxFragmentShader
    });

    var box1 = new THREE.Mesh( new THREE.BoxBufferGeometry( 1, 1, 1), baseBoxMaterial);
    var box2 = new THREE.Mesh( new THREE.BoxBufferGeometry( 1, 1, 1), baseBoxMaterial);
    var box3 = new THREE.Mesh( new THREE.BoxBufferGeometry( 1, 1, 1), baseBoxMaterial);
    var box4 = new THREE.Mesh( new THREE.BoxBufferGeometry( 1, 1, 1), baseBoxMaterial);

    var pattern = getRandom(7)

    if (pattern < 1) {
        box2.position.setZ(-1);
        box3.position.setZ(1);
        box4.position.setZ(2);
        group.position.y -= 1
    } else if (pattern < 2) {
        box2.position.setY(1);
        box3.position.setZ(1);
        box4.position.setZ(2);
        group.position.y -= 2
    } else if (pattern < 3) {
        box2.position.setZ(-1);
        box3.position.setZ(1);
        box4.position.setY(-1);
        group.position.y -= 1
    } else if  (pattern < 4) {
        box2.position.setZ(1);
        box3.position.setY(-1);
        box4.position.setY(-1);
        box4.position.setZ(1);
        group.position.y -= 1
    } else if  (pattern < 5) {
        box2.position.setY(-1);
        box3.position.setZ(-1);
        box4.position.setZ(1);
        box4.position.setY(-1);
        group.position.y -= 1
    } else if (pattern < 6) {
        box2.position.setY(-1)
        box3.position.setZ(1)
        box4.position.setZ(-1)
        box4.position.setY(-1)
        group.position.y -= 1
    } else {
        box2.position.setZ(1)
        box3.position.setZ(-1)
        box3.position.setY(-1)
        box4.position.setZ(-1)
        group.position.y -= 1
    }

    group.add(box1)
    group.add(box2)
    group.add(box3)
    group.add(box4)

    return group
}

function getRandom(max) {
    return Math.random() * max
}


/**
 * Create Borders
 */

function createBorders() {

    let bordersBoxGroup = new THREE.Object3D()

    var borderBox = new THREE.Mesh(new THREE.BoxBufferGeometry(1, 1,  10),  bordersMaterial)
    borderBox.position.setY(-21)
    borderBox.position.setZ(0.5)
    bordersBoxGroup.add(borderBox)

    var shape = new CANNON.Box(new CANNON.Vec3(1 / 2, 1 / 2, 10 / 2))
    let mass = 5
    let body = new CANNON.Body({mass,shape})
    body.position.set(0, -21, 0.5)
    world.addBody(body)
    cannonBlocks.push({threejs: borderBox, cannonjs: body})

    borderBox = new THREE.Mesh(new THREE.BoxBufferGeometry(1, 21,  1), bordersMaterial)
    borderBox.position.setZ(6)
    borderBox.position.setY(-11)
    bordersBoxGroup.add(borderBox)

    shape = new CANNON.Box(new CANNON.Vec3(1 / 2 , 21 / 2, 1 / 2 ))
    mass = 5
    body = new CANNON.Body({mass,shape})
    body.position.set(0, -11, 6)
    world.addBody(body)
    cannonBlocks.push({threejs: borderBox, cannonjs: body})

    borderBox = new THREE.Mesh(new THREE.BoxBufferGeometry(1, 21,  1), bordersMaterial)
    borderBox.position.setZ(-5)
    borderBox.position.setY(-11)
    bordersBoxGroup.add(borderBox)

    shape = new CANNON.Box(new CANNON.Vec3(1 / 2, 21 / 2, 1 / 2))
    mass = 5
    body = new CANNON.Body({mass,shape})
    body.position.set(0, -11, -5)
    world.addBody(body)
    cannonBlocks.push({threejs: borderBox, cannonjs: body})

    return bordersBoxGroup
}


/**
 * Get worldCoordinates of elements in group
 */

function getWorldCoordinatesForElementOfGroup(gr, index) {
    return new THREE.Vector3().setFromMatrixPosition(gr.children[index].matrixWorld)
}

function getWorldCoordinatesForChild(child) {
    return new THREE.Vector3().setFromMatrixPosition(child.matrixWorld)
}

/**
 * Check if our present group had Touch to stop it
 */

function checkGroupTouch(gr, space, yOffset, zOffset) {

    for (var i = 0 ; i < gr.children.length; ++i) {

        var childY = Math.round(getWorldCoordinatesForElementOfGroup(gr, i).getComponent(1))
        var childZ = Math.round(getWorldCoordinatesForElementOfGroup(gr, i).getComponent(2))



        if (yOffset >= 0 && childY - yOffset <= BOUNDY) return true

        if (yOffset == 0 && childY  >= 0) return true

        if (zOffset >= 0 && childZ + zOffset >= BOUNDSZ[1]) return true

        if (zOffset <=   0 && childZ + zOffset <= BOUNDSZ[0]) return true

        if (space[childZ + 4 + zOffset][childY + 20 - yOffset][2] != null) return true
    }

    return false

}

/**
 * if touch scores +100
 */

function changeScores() {

    textGroup.clear()

    if (scores % 1000 === 0) {
        speedX += 1
    }
    let textScores = "Scores: " + scores.toString()

    fontLoader.load( FONT, function ( font ) {
        textGroup.add(crText(textScores, font, 1, 0, 0.5, 6.5, Math.PI /2))
    })


    scene.add(textGroup)
}

/**
 * Update Space
 */

function updateSpace(group) {

    for (var i = 0; i <group.children.length; ++i) {

        var child = group.children[i]
        var childCoordinates = new THREE.Vector3().setFromMatrixPosition(child.matrixWorld)
        var childY =  Math.round(childCoordinates.getComponent(1))
        var childZ =  Math.round(childCoordinates.getComponent(2))

        if (allSpace[childZ + 4][childY + 20][2] === null)
        {
            allSpace[childZ + 4][childY + 20] = [childY, group, child]
        }
        else {
            loose = true
        }
    }

    if (loose) {
        looseTextSet()
    }
}

/**
 * Remove full lines
 */

function removeLines() {

    var fullLine;
    var fullLines = [];
    for (var i = 0; i < HEIGHT; ++i){

        fullLine = true
        for (var j = 0; j < WEIGHT; ++j) {
            if (allSpace[j][i][2] == null){
                fullLine = false
            }
        }

        if (fullLine) {
            fullLines.push(i)
        }
    }

    if (fullLines.length != 0){
        scores += 100 * fullLines.length
        changeScores()
    }


    for (var i = fullLines.length - 1; i >= 0; --i) {
        var index = fullLines[i]
        for (var j = 0; j < WEIGHT; j++) {
            var gr = allSpace[j][index][1]
            var bx = allSpace[j][index][2]
            gr.remove(bx)
            allSpace[j].splice(index, 1)
            allSpace[j].push([-100, null, null])
            for (var k = index;  k < HEIGHT; ++k)
            {
                if (allSpace[j][k][2] != null)
                {
                    allSpace[j][k][0] -= 1
                    var grH = allSpace[j][k][1]
                    var bx1 = allSpace[j][k][2]
                    if (Math.round(grH.rotation.x) === 0) {
                        bx1.position.y -=1
                    } else if (Math.round(grH.rotation.x * 1000) === Math.round(Math.PI/2 * 1000)){
                        bx1.position.z +=1
                    } else if (Math.round(grH.rotation.x * 1000) === Math.round(Math.PI * 1000)) {
                        bx1.position.y +=1
                    } else if (Math.round(grH.rotation.x * 1000) === Math.round(-Math.PI / 2 * 1000)) {
                        bx1.position.z -=1
                    }
                }
            }
        }
    }
}

function looseTextSet() {
    textStartGroup.remove(textStartGroup.children[0])

    fontLoader.load( FONT, function ( font ) {
        textStartGroup.add(crText(LOOSETEXT + scores.toString(), font, 2,  100, 0, -30, -Math.PI / 2))
    })
}

/**
 *  Effect after Loose
 */

function looseEffectShow (looseEffect) {

    if (looseEffect === 0) {
        allGroups.push(nextGroup)

        for (var i = 0; i < allGroups.length; ++i) {

            while (allGroups[i].children.length != 0) {

                var el = allGroups[i].children[0]
                var coord = getWorldCoordinatesForChild(el)

                const box = new THREE.Mesh(el.geometry, el.material)
                box.position.set(coord.getComponent(0), coord.getComponent(1), coord.getComponent(2))
                scene.add(box)

                const shape = new CANNON.Box(new CANNON.Vec3(1 / 2, 1 / 2, 1 / 2))
                let mass = 5
                const body = new CANNON.Body({mass, shape})
                body.position.set(coord.getComponent(0), coord.getComponent(1), coord.getComponent(2))
                world.addBody(body)

                allGroups[i].remove(el)
                cannonBlocks.push({threejs: box, cannonjs: body})

            }
            scene.remove(allGroups[i])
        }

    }

    if (looseEffect === 2) {

        world.step(1/60);

        if (firstTime) {
            for(var i =0; i < cannonBlocks.length; ++i){
                var box = cannonBlocks[i]

                var speed = getRandom(3)
                var direction = getRandom(3)
                if (direction < 1) {
                    direction = 'x'
                } else if (direction < 2) {
                    direction = 'y'
                } else {
                    direction = 'z'
                }

                box.threejs.position[direction] += speed
                box.cannonjs.position[direction] += speed
            }
            firstTime = false
        }
        else {
            for(var i =0; i < cannonBlocks.length; ++i) {
                var box = cannonBlocks[i]
                var speed = 0.15
                var direction = 'y'

                box.threejs.position[direction] += speed
                box.cannonjs.position[direction] += speed
            }
        }

        cannonBlocks.forEach((element)=>{
            element.threejs.position.copy(element.cannonjs.position)
            element.threejs.quaternion.copy(element.cannonjs.quaternion)
        })
    }

}


renderer.setAnimationLoop( function () {

    controls.update()

    const elapsedTime = clock.getElapsedTime()

    if (loose && looseEffect === 0) {

        looseEffectShow(0)
        looseEffect = 1
    }

    if (looseEffect === 1) {
        difElapsedTime = elapsedTime
        looseEffect = 2
    }

    if (looseEffect === 2) {
        if (elapsedTime - difElapsedTime >= 3) {
            difElapsedTime = elapsedTime
            looseEffect = 3
        } else {
            looseEffectShow(2)
        }
    }

    if (looseEffect === 3) {
        gsap.to(camera.position, {duration: 3, x: 50})
        controls.target.set(100, -10,0 )
    }

    if (gameStarted === true){

        if (pressS) {
            if (!checkGroupTouch(group, allSpace, 1, 0)) {
                group.position.setY(group.position.y - 1)
                renderer.render(scene,camera)
            }
            pressS = false
        }
        if (elapsedTime - difElapsedTime >= 1 / speedX) {
            difElapsedTime = elapsedTime;

            if (group) {

                if (checkGroupTouch(group, allSpace, 1, 0)) {

                    updateSpace(group)
                    if (loose)
                    {
                        gameStarted = false
                    }
                    else {
                        removeLines()
                        group = nextGroup
                        group.position.z = 0

                        nextGroup = createGroup()
                        nextGroup.position.z = 10
                        allGroups.push(group)
                        scene.add(nextGroup)
                    }
                } else {
                    group.position.setY(group.position.y - 1)
                }
            }
        }
    }

    var alphaNoise = bordersMaterial.uniforms.alphaNoise.value
    var noise = bordersMaterial.uniforms.noiseScale.value

    if (alphaNoise == 1) {
        if (noise < 50) {
            bordersMaterial.uniforms.noiseScale.value += 0.1
        }
        else {
            bordersMaterial.uniforms.alphaNoise.value = 0
            bordersMaterial.uniforms.noiseScale.value -= 0.1
        }
    }

    if (alphaNoise == 0) {
        if (noise > 1) {
            bordersMaterial.uniforms.noiseScale.value -= 0.1
        }
        else {
            bordersMaterial.uniforms.alphaNoise.value = 1
            bordersMaterial.uniforms.noiseScale.value += 0.1
        }
    }

    var alphaScale = bordersMaterial.uniforms.alphaScale.value
    var scale = bordersMaterial.uniforms.scale.value

    if (alphaScale == 1) {
        if (scale < 20) {
            bordersMaterial.uniforms.scale.value += 0.01
        }
        else {
            bordersMaterial.uniforms.alphaScale.value = 0
            bordersMaterial.uniforms.scale.value -= 0.01
        }
    }

    if (alphaScale == 0) {
        if (scale > 1) {
            bordersMaterial.uniforms.scale.value += 0.01
        }
        else {
            bordersMaterial.uniforms.alphaScale.value = 0
            bordersMaterial.uniforms.scale.value += 0.01
        }
    }

    // Render
    renderer.render( scene, camera );

} );